import axios from "axios";
import {toast} from "react-toastify";

export const handleSave = async (data, auth0Token, isSavingSetter) => {
    return axios.post(`${process.env.REACT_APP_RIS_API_URL}/data-subscriptions/data-subscriptions/save-subscriptions`, data, {
        headers: {
            'Content-Type': 'application/json',
            'authorization': `Bearer ${auth0Token}`
        },
    }).then((response) => {
        toast.success(`Successfully saved subscriptions ${response.data.companyId ?? ''}`)
    }).catch((e) => {
        toast.error(e)
    }).finally(() => isSavingSetter(false))
}
